import React, { useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Import your images
import ufpg1 from "../assets/uf.png";
import ufpg2 from "../assets/ufpg2.png";
import ufpg3 from "../assets/ufpg3.png";
import ufpg4 from "../assets/ufpg4.png";
import ufpg5 from "../assets/ufpg5.png";
import ufpg6 from "../assets/ufpg6.png";

// Custom Arrow Component
const CustomArrow = ({ className, style, onClick, direction }) => (
  <div
    className={className}
    style={{
      ...style,
      background: "rgba(0, 0, 0, 0.5)",
      borderRadius: "50%",
      width: "40px",
      height: "40px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
      zIndex: 1,
      position: "absolute",
      top: "8%",
      [direction]: "10px", // Adjust as necessary
      transform: "translateY(-50%)", // Center vertically
    }}
    onClick={onClick}
  >
    {direction === "left" ? "←" : "→"}
  </div>
);

const UpComing = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 600,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    nextArrow: <CustomArrow direction="right" />,
    prevArrow: <CustomArrow direction="left" />,
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  return (
    <div
      style={{
        position: "relative",
        width: "70%",
        margin: "0 auto",
        marginTop: "10px",
      }}
    >
      <Slider {...settings}>
        <div>
          <img
            src={ufpg1}
            alt="ufpg1"
            style={{ width: "100%", height: "auto" }}
          />
        </div>
        <div>
          <img
            src={ufpg2}
            alt="ufpg2"
            style={{ width: "100%", height: "auto" }}
          />
        </div>
        <div>
          <img
            src={ufpg3}
            alt="ufpg3"
            style={{ width: "100%", height: "auto" }}
          />
        </div>
        <div>
          <img
            src={ufpg4}
            alt="ufpg4"
            style={{ width: "100%", height: "auto" }}
          />
        </div>
        <div>
          <img
            src={ufpg5}
            alt="ufpg5"
            style={{ width: "100%", height: "auto" }}
          />
        </div>
        <div>
          <img
            src={ufpg6}
            alt="ufpg6"
            style={{ width: "100%", height: "auto" }}
          />
        </div>
      </Slider>
    </div>
  );
};

export default UpComing;
