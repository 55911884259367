import React from "react";
import Button from "@mui/material/Button";
import { doctorDetailsGet,aws_url } from "../../Service/Services";
import { doctorDetailsUpdate } from "../../Service/Services";
import { useState, useEffect } from "react";
import { UploadButton } from "../../CustomStyles/Styles";
import ImageUploadFile from "./ImageUploadFile";
import toast from "react-hot-toast";
import { ListItem, Typography } from "@mui/material";
import ResponsiveDiv from "./styles/style";
import CarouselComponent from "./App";
import { createTheme } from "@mui/material";
import { ThemeProvider } from "styled-components";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { ProImageUpload } from "../../Service/Services";
import DeleteIcon from "@mui/icons-material/Delete";
import {
    IconButton,
    
  } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
// import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from "@mui/material/DialogTitle";
import ImageUpload from "./ImageUpload";

const Features = () => {
  const DEFAULT_IMAGE ="http://healthmudraa.aws.s3-website.ap-south-1.amazonaws.com/1718098598539-blank-profile-picture-973460_1280.png";
  const theme = createTheme({
    palette: {
      type: "light",
      primary: {
        main: "#133680",
      },
      secondary: {
        main: "#f50057",
      },
      text: {
        primary: "#000000",
      },
    },
    typography: {},
  });
  const [image, setImage] = useState({ preview: "", data: "" }); 
   const [features, setFeatures] = useState([]);



  const [imagechanged, setImagechanged] = useState(false);
  const [edit, setEdit] = useState("");
  

  const [details, setDetails] = useState({
    firstname: "",
    lastname: "",
    mobile: "",
    location: "",
    specilization: [],
    gender: "",
    languages: [],
    seotitle: "",
    seodescription: "",
    homevisit: false,
    onlinevisit: false,
    profilepicurl: "",
    qualifications: [],
    experiences: [],
    registration: [],
    govtId: [],
    awards: [],
    about: "",
    features: [],
  });
  const [feat, setFeat] = useState({link:"",logourl:""});
  const [open, setOpen] = React.useState(false);
  const [idx, setIdx] = useState("");

  const handleClickOpen = (val) => {
    setIdx(val);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setFeat({ link: "", logourl: "" });
    setImage({ preview: "", data: "" });
    setImagechanged(false);
    setIdx("");
  };
  const forDoctorGet = async () => {
    try {
      const response = await doctorDetailsGet();
      console.log("Doctor Get Response:", response);
      if (response?.data.status) {
        setDetails(response.data?.data);
        setFeatures(response.data?.data.features);
        console.log(response.data.data);
      } else {
        toast.error(response?.data.message);
      }
    } catch (error) {
      toast.error("Error occurred while fetching doctor details!");
    }
  };


  const forSubmit = async () => {
    try {
      const responseJson = await doctorDetailsUpdate(details);
      console.log("Doctor Submit Response:", responseJson);
      if (responseJson.data.status) {
        toast.success(responseJson.data.message);
      } else {
        toast.error(responseJson.data.message);
      }
    } catch (error) {
      toast.error("Error occurred while submitting doctor details!");
    }
  };


  useEffect(() => {
    forDoctorGet();
    handleDelete();
  }, []);

  const resetFeatureForm = () => {
    setFeat({ link: "", logourl: "" });
    setImage({ preview: "", data: "" });
    setImagechanged(false);
  };
  

  const clicked = () => { 
    
    handleFeature(); 
    console.log(features);
    console.log("hello"); 
    console.log(feat);
    console.log(details); 
    resetFeatureForm();
    setTimeout(() => {
      console.log('hello',details);
      forSubmit();
    }, 4000);
  };

  const handleDelete = (i) => {
    let features = details.features;
    features.splice(i, 1);
    console.log(features);
    setDetails({ ...details, features });
    console.log(details);
    console.log(i);
  };
  const handleInputChnage = (e) => {
    const { name, value } = e.target;
    setFeat({ ...feat, [name]: value });
    console.log(feat);
  }
  
  const forUploadImage = async (type) => {  
    let data = new FormData(); 
    data.append("image", image.data); 
    const response = await ProImageUpload(data); 
    if (response.data.status) {  
      return response.data.data.fileName;  
    }  
    return " "; 
  };

  

  const handleFeature = async () => {

    let file;
    if (imagechanged) {
      try {
        file = await forUploadImage("features");
        if (!file) {
          throw new Error("Failed to upload image.");
        }
        setFeat((prev) => ({ ...prev, logourl: file }));
      } catch (error) {
        toast.error("Error uploading image. Please try again.");
        // setLoading((prev) => ({ ...prev, experience: false }));
        return;
      }
    }

    if (!feat.link) {
      toast.error("Link is required.");
      // setLoading((prev) => ({ ...prev, experience: false }));
      return;
    }
    
   
    if (!file && !feat.logourl) {
      toast.error("Please upload an link logo.");
      return;
    }

    const updatedNewFeat = [...details.features];
    updatedNewFeat.push({
      ...feat,
      logourl: file?file: feat.logourl,
    });

    setDetails((prevState) => ({
      ...prevState,
      features: updatedNewFeat,
    }));
    setFeatures([...updatedNewFeat])

    setFeat({
      link: "",
      logourl: "",
    });

    setImage({ preview: "", data: "" });
    setImagechanged(false);

    // setLoading((prev) => ({ ...prev, experience: false }));
  };

  const handleDialogImageUpload = async () => {
    if (!imagechanged || !image.data) return feat.logourl; // Return existing logourl if no new image uploaded
  
    try {
      const formData = new FormData();
      formData.append("image", image.data);
      const response = await ProImageUpload(formData);
      if (response.data.status) {
        return response.data.data.fileName; // Return the new logourl
      }
    } catch (error) {
      toast.error("Error uploading image. Please try again.");
    }
    return feat.logourl; // Fallback to existing logourl if upload fails
  };

  return (
    <div>
      <ThemeProvider theme={theme}>
        <ResponsiveDiv>
          <CarouselComponent />
        </ResponsiveDiv>

        <div className="flex  justify-center ml-16 flex-column">
          <Typography variant="body1" color="textSecondary">
            <b>Featured in</b> (Give Articles . News paper links){" "}
          </Typography>
          <div className="flex flex-column mt-4 mr-64">
            <input
              className="bg-gray-200 onFocus:border-gray-200 w-full h-8 ml-8"
              type="text"
              name="link"
              id="input"
              value={feat.link}
              onChange={(e)=>handleInputChnage(e)}
            />
            <div className="flex gap-4 ">
            <div className="mt-4">
           
           <ImageUploadFile
                setForm={setFeat}
                fieldname={"logourl"}
                imageurl={
                  feat.logourl !== ""
                    ? `${aws_url}/${feat.logourl}`
                    : ""
                }  
                name={"features"}  
                tempimage={image}  
                setImagechanged={setImagechanged}  
                setTempImage={setImage} 
                card={true}  
                edit={edit}  
                setEdit={setEdit}  
              />
               <p className="ml-8 text-blue-950">
              Upload logo
            </p>
              </div>
              <div className="flex mt-16">
              <Button
              className="h-6 w-8"
              variant="contained"
              onClick={() => {
                clicked();
              }}
            >
              Add
            </Button>
            </div>
              </div>
            
            
            
          </div>
          <ol>
            {details.features.length ? (
              <div className="flex gap-4 ml-[500px] mt-2">
                <p>edit</p> <p>delete</p>
              </div>
            ) : null}
            {features && 
              details.features.map((f, i) => { 
                return (
                  <div key={i} className="flex">
                    <li className="flex justify-between gap-2 w-[600px]">
                      <img className='w-8 h-8' src={`${aws_url}/${f.logourl}`} alt="logo" />
                      <p>
                        {"  "}
                        {f.link}
                      </p>
                      <div className="flex gap-6">
                        <IconButton onClick={() => handleClickOpen(i)}>
                          <EditIcon />
                        </IconButton>
                        <Dialog
                          open={open}
                          onClose={handleClose}
                          PaperProps={{
                            component: "form",
                            onSubmit: async(event) => {
                              event.preventDefault();
                              const formData = new FormData(
                                event.currentTarget
                              );
                              const formJson = Object.fromEntries(
                                formData.entries()
                              );
                              const input = formJson.link;
                              const updatedLogoUrl = await handleDialogImageUpload();

      const updatedFeatures = details.features.map((item, i) =>
        i === idx ? { ...item, link: input, logourl: updatedLogoUrl } : item
      );
                              setDetails((prev)=>({ ...prev, features:updatedFeatures }));
                              console.log(updatedFeatures,details);
                              setIdx("");
                              handleClose();
                            },
                          }}
                        >
                          <DialogTitle>Edit Feature Link</DialogTitle>
                          <DialogContent>
                            <TextField
                              autoFocus
                              required
                              margin="dense"
                              id="name"
                              name="link"
                              defaultValue={details.features[idx]?.link}
                              label="Feature link"
                              type="text"
                              fullWidth
                              variant="standard"
                            />
                             <ImageUploadFile
      setForm={setFeat}
      fieldname="logourl"
      imageurl={feat.logourl ? `${aws_url}/${feat.logourl}` : ""}
      name="features"
      tempimage={image}
      setImagechanged={setImagechanged}
      setTempImage={setImage}
      card={true}
      edit={edit}
      setEdit={setEdit}
    />
                          </DialogContent>
                          <DialogActions>
                            <Button onClick={handleClose}>Cancel</Button>
                            <Button type="submit">Update</Button>
                          </DialogActions>
                        </Dialog>
                        <IconButton onClick={() => handleDelete(i)}>
                          <DeleteIcon />
                        </IconButton>{" "}
                      </div>
                    </li>
                  </div>
                );
              })}
          </ol>
          <div
            className=" mt-3"
            style={{
              paddingTop: "50px",
              paddingBottom: "20px",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <UploadButton
              onClick={forSubmit}
              style={{ fontFamily: "Montserrat" }}
            >
              Save Changes
            </UploadButton>
          </div>
        </div>
      </ThemeProvider>
    </div>
  );
};

export default Features;
