import React, { useState, useEffect } from "react";
import {
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  createTheme,
  ThemeProvider,
  TableHead,
  TextField,
  IconButton,
  MenuItem,
  Popover,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AddIcon from "@mui/icons-material/Add";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";

import {
  aws_url,
  adminDepartmentCreate,
  adminDepartmentgetAll,
  adminDepartmentUpdate,
  adminDepartmentDelete,
  // adminDepartmentDelete, // Enable delete service
} from "../../Service/Services";
import ImageUpload from "../../DoctorPannel/DoctorComponents/ImageUpload";

const DepartmentPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [showPopup, setShowPopup] = useState(false);
  const [open, setOpen] = useState(null);
  const [editid, setEditid] = useState(null);
  const [form, setForm] = useState({
    departmentName: "",
    departmentImage: "",
  });
  const [departmentData, setDepartmentData] = useState([]);

  // Fetch all departments
  const Departmentget = async () => {
    try {
      const response = await adminDepartmentgetAll();
      const departmentData = response.data?.Data;
      setDepartmentData(departmentData);
      if (response.status === 200) {
        toast.success(response.data.message);
      } else {
        toast.error("Failed to get department.");
      }
    } catch (error) {
      toast.error(
        error.response ? error.response.data.message : "An error occurred."
      );
      console.error("Error while fetching departments:", error);
    }
  };

  // Initial fetch of departments
  useEffect(() => {
    Departmentget();
  }, []);

  // Toggle popup
  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  // Handle profile field changes
  const forProfileChange = (e) => {
    const { name, value } = e.target;
    setForm((prev) => ({ ...prev, [name]: value }));
  };

  // Handle submit for department creation/update
// Handle submit for department creation/update
const forSubmit = async (event) => {
  event.preventDefault(); // Prevent the default form submission

  try {
    let response;
    if (editid) {
      // Updating an existing department
      response = await adminDepartmentUpdate({
        _id: editid, // Pass the ID of the department being edited
        departmentName: form.departmentName, // Pass the updated department name
        departmentImage: form.departmentImage, // Pass the updated department image
      });
    } else {
      // Creating a new department
      response = await adminDepartmentCreate(form); // Pass form object directly
    }

    if (response && response.status === 200) {
      toast.success(response.data.message);
      Departmentget(); // Refresh the department list
      togglePopup(); // Close the popup
      setForm({ departmentName: "", departmentImage: "" }); // Clear the form
      setEditid(null); // Reset edit ID after successful update
    } else {
      toast.error("Failed to save department.");
    }
  } catch (error) {
    toast.error(
      error.response ? error.response.data.message : "An error occurred."
    );
    console.error("Error while submitting:", error);
  }
};


  // Handle edit action
  const handleEditDepartment = (id) => {
    const departmentToEdit = departmentData.find((d) => d._id === id);
    if (departmentToEdit) {
      setForm({
        departmentName: departmentToEdit.departmentName,
        departmentImage: departmentToEdit.departmentImage,
      });
      setEditid(id);
      togglePopup();
    }
  };

  // Handle delete action
  const handleDeleteUser = async (id) => {
    if (!id) return;
    try {
      const response = await adminDepartmentDelete(id);
      if (response.status === 200) {
        toast.success(response.data.message);
        Departmentget(); // Refresh department list
      } else {
        toast.error("Failed to delete department.");
      }
    } catch (error) {
      toast.error(
        error.response ? error.response.data.message : "An error occurred."
      );
      console.error("Error while deleting department:", error);
    }
  };
  

  // Handle menu actions
  const handleOpenMenu = (event, id) => {
    setOpen(event.currentTarget);
    setEditid(id);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  return (
    <React.Fragment>
      <ThemeProvider
        theme={createTheme({
          /* Customize your theme here */
        })}
      >
        <Container>
          {/* Header */}
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={5}
          >
            <Typography variant="h4" gutterBottom>
              Manage Departments
            </Typography>
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => {
                setEditid(null); // Reset edit ID for new department creation
                setForm({ departmentName: "", departmentImage: "" }); // Reset form
                togglePopup();
              }}
            >
              New Department
            </Button>
          </Stack>

          {/* Department List */}
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>DEPARTMENT NAME</TableCell>
                  <TableCell>IMAGE</TableCell>
                  <TableCell>ACTIONS</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {departmentData.map((department) => (
                  <TableRow key={department._id}>
                    <TableCell>{department._id}</TableCell>
                    <TableCell>{department.departmentName}</TableCell>
                    <TableCell>
                      <img
                        src={
                          department.departmentImage.startsWith("http")
                            ? department.departmentImage
                            : `${aws_url}/${department.departmentImage}`
                        }
                        alt={department.departmentName}
                        style={{
                          width: "50px",
                          height: "50px",
                          objectFit: "cover",
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <IconButton
                        size="large"
                        color="inherit"
                        onClick={(e) => handleOpenMenu(e, department._id)}
                      >
                        <MoreVertIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          {/* Popup for Creating/Editing */}
          {showPopup && (
            <form onSubmit={forSubmit}>
              <div className="fixed inset-0 flex items-center justify-center z-50">
                <div className="bg-white shadow-lg rounded-lg p-6">
                  <TextField
                    required
                    fullWidth
                    value={form.departmentName}
                    autoComplete="off"
                    name="departmentName"
                    onChange={forProfileChange}
                    placeholder="Department Name*"
                  />

                  <ImageUpload
                    setForm={setForm}
                    fieldname={"departmentImage"}
                    imageurl={
                      form.departmentImage
                        ? `${aws_url}/${form.departmentImage}`
                        : ``
                    }
                  />

                  <div className="flex items-center justify-between">
                    <button
                      className="mt-4 bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
                      type="submit"
                    >
                      {editid ? "Update" : "Save"}
                    </button>
                    <button
                      className="mt-4 bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600"
                      onClick={togglePopup}
                      type="button"
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </form>
          )}

          {/* Popover Menu for Edit/Delete */}
          <Popover
            open={Boolean(open)}
            anchorEl={open}
            onClose={handleCloseMenu}
          >
            <MenuItem
              onClick={() => {
                handleEditDepartment(editid);
                handleCloseMenu();
              }}
            >
              <EditIcon />
              Edit
            </MenuItem>

            <MenuItem
              sx={{ color: "error.main" }}
              onClick={() => {
                handleDeleteUser(editid);
                handleCloseMenu();
              }}
            >
              <DeleteIcon />
              Delete
            </MenuItem>
          </Popover>
        </Container>
      </ThemeProvider>
    </React.Fragment>
  );
};

export default DepartmentPage;
