import React, { useEffect, useState, useCallback, lazy, Suspense } from "react";
import { Box, TextField, Button, InputAdornment } from "@mui/material";
import { Search } from "@mui/icons-material";
import { userHomePage } from "../Service/Services";
import toast from "react-hot-toast";
import ShareIcon from "@mui/icons-material/Share";
import { HMCard, VideoShareWrapper } from "./DoctorCard/DoctorCardStyles";
import { Flex, FlexCol } from "../styles/CommonStyles";
import { useNavigate } from "react-router-dom";
import RenderModalOrBottomSheet from "../Components/common/RenderModalBS";
import LeadGenerationForm from "../Components/common/Lead-Generation";
import debounce from "lodash/debounce";
import { useInView } from "react-intersection-observer";
import { Helmet } from "react-helmet-async";
import "plyr-react/plyr.css";

// Lazy loading components
const DoctorCard = lazy(() => import("./DoctorCard"));
const Plyr = lazy(() => import("plyr-react"));

const buttonStyles = {
  margin: "0 1rem", // Space between buttons
  padding: "0.5rem 1rem", // Padding inside buttons
  borderRadius: "8px", // Rounded corners
  fontWeight: "bold", // Bold text
  textTransform: "capitalize", // Capitalize text
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)", // Shadow for depth
  "&:disabled": {
    backgroundColor: "#e0e0e0", // Gray background for disabled state
    color: "#b0b0b0", // Gray text color for disabled state
    cursor: "not-allowed", // Change cursor to indicate disabled state
  },
};

const previousButtonStyles = {
  ...buttonStyles,
  backgroundColor: "#D3E9FD", // Blue background
  color: "black", // White text color
  "&:hover": {
    backgroundColor: "#133682",
    color: "#fff", // Darker blue on hover
  },
};

const nextButtonStyles = {
  ...buttonStyles,
  backgroundColor: "#D3E9FD", // Green background
  color: "black", // White text color
  "&:hover": {
    backgroundColor: "#133682",
    color: "#fff", // Darker blue on hover
  },
};

const Videos = () => {
  const [doctor, setDoctor] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();
  const [isBtsVisible, setShowBts] = useState(false);
  const [doctorId, setDoctorId] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [videosToShow, setVideosToShow] = useState([]);

  const itemsPerRow = 3; // Set items per row to 3
  const rowsToShow = 7; // Show 21 videos at a time (3 items per row * 7 rows)

  // Fetch and set videos
  useEffect(() => {
    const fetchVideos = async () => {
      try {
        setLoading(true);
        const response = await userHomePage();
        if (response?.data.status) {
          setDoctor(response.data?.data);
          setVideosToShow(
            response.data?.data.slice(0, itemsPerRow * rowsToShow)
          );
        } else {
          toast.error(response.data?.message);
        }
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };

    fetchVideos();
  }, []);

  const handleSearch = async (term) => {
    try {
      setLoading(true);
      const response = await userHomePage(term);
      if (response?.data.status) {
        setDoctor(response.data?.data);
        setVideosToShow(response.data?.data.slice(0, itemsPerRow * rowsToShow));
        setCurrentPage(1); // Reset to the first page on new search
      } else {
        toast.error(response.data?.message);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  // Debounced search to avoid multiple calls on quick typing
  const debouncedSearch = useCallback(
    debounce((term) => handleSearch(term), 500),
    []
  );

  useEffect(() => {
    if (searchTerm) {
      debouncedSearch(searchTerm);
    } else {
      handleSearch("");
    }
  }, [searchTerm, debouncedSearch]);

  const handleNext = () => {
    const nextPage = currentPage + 1;
    window.scrollTo({ top: 0, behavior: "smooth" });

    setCurrentPage(nextPage);
    setVideosToShow(
      doctor.slice(
        (nextPage - 1) * itemsPerRow * rowsToShow,
        nextPage * itemsPerRow * rowsToShow
      )
    );
  };

  const handlePrevious = () => {
    const previousPage = currentPage - 1;
    if (previousPage < 1) return; // Prevent going below page 1

    window.scrollTo({ top: 0, behavior: "smooth" });

    setCurrentPage(previousPage);
    setVideosToShow(
      doctor.slice(
        (previousPage - 1) * itemsPerRow * rowsToShow,
        previousPage * itemsPerRow * rowsToShow
      )
    );
  };

  const handleShareVideo = async (e, link) => {
    e.stopPropagation(); // Prevents navigation
    e.preventDefault(); // Prevents default behavior

    const webShareSupported =
      typeof window != "undefined" ? "canShare" in window?.navigator : false;

    if (webShareSupported) {
      const data = {
        url: link, // Use the video link here
        title: `Checkout this informative health video!`,
        text: `Hey, I'd like to recommend Healthmudraa to learn more about health-related cures before going to any pharma and avoid taking random medicines`,
      };
      if (navigator.canShare(data)) {
        try {
          await navigator.share(data);
        } catch (err) {
          if (err.name !== "AbortError") {
            console.error(err.name, err.message);
          }
        }
      }
    } else {
      navigator.clipboard.writeText(link);
      toast.success("Copied link to share");
    }
  };

  const handleAppointmentBts = (e, doctorId) => {
    document
      .querySelector(".widget-visible")
      .setAttribute("style", "display:none !important");
    setDoctorId(doctorId);
    setShowBts(true);
    e.stopPropagation();
    e.preventDefault();
  };

  return (
    <>
      <Helmet>
        <title>Healthmudraa-Videos</title>
        <meta name="description" content="Videos page description comes here" />
      </Helmet>

      <Box display="flex" justifyContent="center" marginTop={"1rem"}>
        <TextField
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          variant="outlined"
          placeholder="Search..."
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Button onClick={() => debouncedSearch(searchTerm)}>
                  <Search />
                </Button>
              </InputAdornment>
            ),
          }}
          sx={{ width: "50vw", "@media (max-width: 600px)": { width: "90vw" } }}
        />
      </Box>

      <div className="container mt-3">
        {loading ? (
          <div className="spinner"></div>
        ) : (
          <div className="row justify-content-center">
            <div className="col-12 text-center my-10">
              <h1 className="fw-bold my-5">Expert Health Advice</h1>
            </div>

            <Suspense fallback={<div>Loading...</div>}>
              {videosToShow.length > 0 &&
                videosToShow.map((item) => (
                  <LazyLoadVideoCard
                    key={item.id} // Ensure unique key for each item
                    item={item}
                    navigate={navigate}
                    handleShareVideo={handleShareVideo}
                    handleAppointmentBts={handleAppointmentBts}
                  />
                ))}
            </Suspense>

            {videosToShow.length < doctor.length && (
              <div className="text-center mt-4">
                <Button
                  onClick={handlePrevious}
                  disabled={currentPage === 1} // Disable button if on the first page
                  sx={previousButtonStyles} // Apply styles
                >
                  Previous
                </Button>
                <Button
                  onClick={handleNext}
                  sx={nextButtonStyles} // Apply styles
                >
                  Next
                </Button>
              </div>
            )}
          </div>
        )}
      </div>

      <RenderModalOrBottomSheet
        title="Book an Appointment"
        isVisible={isBtsVisible}
        onClose={() => setShowBts(false)}
      >
        <Flex>
          <LeadGenerationForm
            onClose={() => setShowBts(false)}
            doctorId={doctorId}
          />
        </Flex>
      </RenderModalOrBottomSheet>
    </>
  );
};

const LazyLoadVideoCard = React.memo(
  ({ item, navigate, handleShareVideo, handleAppointmentBts }) => {
    const { ref, inView } = useInView({
      triggerOnce: true, // Load the video only once when it comes into view
      threshold: 0.5, // Load the video when 50% of it is in view
    });

    return (
      <div
        className="col-lg-4 col-md-6 col-sm-12 col-12"
        style={{ marginBottom: "20px", borderRadius: "12px" }}
        ref={ref}
      >
        <HMCard
          onClick={() => {
            navigate(
              `/videos/${decodeURIComponent(
                item.title.split(" ").join("-").toString()
              )}`
            );
          }}
        >
          <FlexCol>
            <VideoShareWrapper>
              <Suspense fallback={<div>Loading video...</div>}>
                {inView && item.video.link.length > 0 && (
                  <Plyr
                    source={{
                      type: "video",
                      sources: [
                        {
                          src: item.video.link,
                          provider: "youtube",
                        },
                      ],
                    }}
                  />
                )}
              </Suspense>

              <ShareIcon
                className="shareIcon"
                sx={{ fontSize: "1rem", marginRight: "0.5rem" }}
                onClick={
                  (e) => handleShareVideo(e, item.video.link) // Pass the video link here
                }
              />
            </VideoShareWrapper>
            <DoctorCard
              item={item}
              videocode={item.title}
              handleBtsModal={handleAppointmentBts}
            />
          </FlexCol>
        </HMCard>
      </div>
    );
  }
);

export default Videos;
